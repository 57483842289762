<script setup lang="ts">
const i18n = useI18n()
const switchLocalePath = useSwitchLocalePath()
const availableLocales = computed(() => {
  return i18n.locales.value
    .map((i) => {
      if (typeof i === 'object')
        return { code: i.code, name: i.name }

      else
        return { code: i, name: i }
    })
    .filter(i => i.code !== i18n.locale.value)
})
</script>

<template>
  <NuxtLink v-for="locale in availableLocales" :key="locale.code" class="text-5xl text-center" :to="switchLocalePath(locale.code)">
    <Icon v-if="i18n.locale.value !== 'de'" v-umami="'language_german_button'" name="cif:de" />
    <Icon v-else v-umami="'language_english_button'" name="cif:gb" />
  </NuxtLink>
</template>
