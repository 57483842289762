<template>
  <footer class="flex flex-col justify-center items-center bg-white px-10 gap-y-4 footer-shadow font-bold text-sm lg:text-xl pb-5 pt-4 mt-4">
    <div class="lg:hidden w-fit">
      <LanguageSwitcher />
    </div>
    <div class="w-full border border-details_light_grey lg:hidden" />
    <div class="self-start lg:order-4 flex justify-between w-full items-center">
      <div>
        <Icon class="mr-1" name="ic:baseline-copyright" />
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        <span>2024 Bettenbörse Bonn</span>
      </div>
      <div class="max-lg:hidden">
        <LanguageSwitcher />
      </div>
    </div>
    <div class="flex flex-col lg:flex-row lg:w-full lg:justify-between gap-y-4 self-center items-center">
      <NuxtLink to="https://klimadelegation.de/" :external="true" target="_blank" class="max-lg:hidden">
        <NuxtPicture :modifiers="{ fit_inside: '' }" width="176" sizes="176px 2xl:176px" loading="lazy" class="object-contain w-44 h-[4.5rem]" src="/Klimadelegation_Logo_groß_logoblau.png" />
      </NuxtLink>

      <div class="flex flex-col lg:flex-row gap-y-4 lg:gap-x-10 lg:items-center">
        <LocalizedLink to="/impressum">
          {{ $t('legal.impressum.link') }}
        </LocalizedLink>
        <LocalizedLink to="/dsgvo">
          {{ $t('legal.dsgvo.link') }}
        </LocalizedLink>
        <LocalizedLink v-umami="'confirmation_email_not_received_footer'" to="/resendConfirmationEmail">
          {{ $t('resend_confirmation_email.question') }}
        </LocalizedLink>
      </div>

      <NuxtLink href="https://fridaysforfuture-bonn.de/" :external="true" target="_blank" class="max-lg:hidden">
        <NuxtPicture :modifiers="{ fit_inside: '' }" height="72" sizes="72px 2xl:72px" loading="lazy" class="object-contain h-[4.5rem]" src="/FFFBonn.jpg" />
      </NuxtLink>
    </div>
    <div class="w-full border border-details_light_grey lg:hidden" />
    <div class="flex justify-between h-12 lg:hidden w-full items-center">
      <NuxtLink v-umami="'klimadelegation_logo_footer'" to="https://klimadelegation.de/" :external="true" target="_blank">
        <NuxtPicture width="136" loading="lazy" sizes="136px 2xl:136px" class="object-contain" :modifiers="{ fit_inside: '' }" src="/Klimadelegation_Logo_groß_logoblau.png" />
      </NuxtLink>
      <NuxtLink v-umami="'fff_logo_footer'" href="https://fridaysforfuture-bonn.de/" :external="true" target="_blank">
        <NuxtPicture height="48" loading="lazy" sizes="48px 2xl:48px" class="object-contain" :modifiers="{ fit_inside: '' }" src="/FFFBonn.jpg" />
      </NuxtLink>
    </div>
  </footer>
</template>
